.Resource{
    position: relative;
    float: left;
    width: 95%;
    height: 100%;
    overflow: visible;
    padding: 2%;
    box-sizing: border-box;
    text-align: initial;
}

.Resource .dialog-overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(0,0,0,0.7);
}

.Resource .dialog{
    position: absolute;
    min-height: 50vh;
    width: 27vw;
    max-width: 60vw;
    max-height: 80vh;
    top: 10vh;
    left: 0;
    right: 0;
    background:#005441;
    padding: 2vw;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 1vw rgba(20,20,20,1);
    border-radius: 0.5vw;
    font-size: 0.75em;
}

.Resource .dialog h1{
    font-size: 1.2vw;
}

.Resource .dialog.teamchooser h1{
    padding-left: 1.9vh;
}

.Resource .dialog h2{
    padding-left: 0;
}

.Resource .dialog .container{
    overflow: auto;
}

.Resource .dialog .unit-container{
    overflow: auto;
    display: flex;
    justify-content: center;
}

.Resource .dialog .unit-container .unit img{
    width: 7.5vw;
}

.Resource .dialog label{
    font-size: 0.8vw;
    line-height: 0.8vw;
    padding-bottom: 0.2vw;
}

.Resource .dialog .date-picker-custom{
    line-height: 2vw;
    font-size: 0.8vw;
}

.Resource .dialog .dialog-btn{
    margin-top: 3vh;
    width: 50%;
    height: 5vh;
    line-height: 5vh;
    font-size: 2vh;
    background: rgba(30,30,30,0.9);
    text-align: center;
    color: white;
    box-shadow: 0 0 0.1vh rgba(0,0,0,0.8);
    margin-left: auto;
    margin-right: auto;
    border-radius: 3vw;
}

.Resource .dialog .dialog-btn.disabled{
    background: rgba(10,10,10,0.5);
    color: white;
}

.Resource .dialog.timechooser{
    z-index: 10;
    min-width: 0;
    min-height: 0;
}

.Resource .dialog.teamchooser{
    top: 4vw;
    width: 50vw;
}

.Resource .dialog.teamchooser h2{
    font-size: 1vw;
    margin-bottom: 0.6vw;
}

.Resource .dialog.teamchooser .team-row{
    overflow: auto;
    padding-left: 2vh;
}

.Resource .dialog.teamchooser .team{
    float: left;
    min-width: 5vw;
    color: black;
    text-align: center;
    padding: 0.7vw;
    box-sizing: border-box;
    margin-right: 1vw;
    margin-bottom: 0.8vw;
    border-radius: 0.5vh;
    background: rgba(255, 255, 255, 0.07);
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1)
}

.Resource .dialog.teamchooser .team.active{
    background: black;
    color: rgba(255,255,255,1);
}

.Resource .dialog.teamchooser .team .text{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.Resource .padding-content{
    position: relative;
    width: 100%;
    height: 100%;
}

.Resource h2{
    text-align: left;
    padding-left: 1.5%;
}

.Resource .button{
    background: rgb(180,180,180);
    border: 1px solid black;
    padding: 10px 20px;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.Resource .free-unit{
    float: left;
    padding: 20px;
    border: 1px solid black;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.Resource .free-unit p, .Resource .free-unit .button{
    float: left;
}

.Resource .units-container{
    float: left;
    width: 50%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    box-sizing: border-box;
    border-radius: 5px;
}

.Resource .units{
    /*overflow: auto;*/
    max-height: 79vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1.5% 0.8%;
}

.Resource .calendar{
    float: left;
    width: 50%;
    padding-left: 2%;
    box-sizing: border-box;
}

.Resource .calendar .calender-chooser{

}

.booking-header-wrapper{
    display: inline-flex;
    width: 100%;
}

.book-header {
    width: 50vh;
}


.back-header{
    text-align: end;
    /*padding: 0.9vh;*/
    margin: auto;
    padding-right: 2vh;
    line-height: 2vh;
}

.back-arrow{
    font-size: 4vh;
    margin: auto;
    width: 5vh;
    text-align: center;
    padding-bottom: 0.4vh;

}

.Resource .unit{
    position: relative;
    text-align: center;
    float: left;
    margin-right: 0.5vw;
    padding-bottom: 0.6vw;
}

.Resource .unit-category{
    position: relative;
    text-align: center;
    float: left;
    /*
    margin-right: 0.5vw;
    width: 4.9vw;
    */
    margin: 1.5vh;
    padding: 3vw;
    font-size: 2.3vh;
    border: 0.1vh white solid;
    border-radius: 1vh;
    box-sizing: border-box;
}

.left-arrow{

}

.Resource .unit img{
    width: 4.9vw;
    border-radius: 1vh;
    box-sizing: border-box;
}

.Resource .units .unit.booked img{
    border: 0.3vw red solid;
}

.Resource .calendar{
    width: 50%;
}
.Resource .calendar .row{
    margin-bottom: 0.1%;
}


.Resource .calendar .row,
.Resource .calendar .row .name,
.Resource .calendar .row .dd-cell,
.Resource .calendar .row .plans,
.Resource .calendar .row .plans .plan{
    min-height: 2vh;
}

.Resource .calendar .row .plans{
    left: 0;
}

.Resource .calendar .row .dd-hour{
    font-size: 0.6vw;
}

.Resource .calendar .row .name{
    font-size: 1.2vh;
    line-height: 2vh;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    padding-left: 0.3%;
    padding-right: 0;
    margin-left: 0.2%;
    width: 9.8%;
    background: rgba(30,30,30,0.9);
    box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.Resource .calendar .header{
    background: linear-gradient(to left, rgba(20,20,20,0.08), rgba(20,20,20,0));
    border-top-right-radius: 1.5vh;
}

.Resource .calendar .row .header-name{
    float: right;
}

.Resource .calendar .plans .plan{
    line-height: 1.5vh;
    font-size: 1.5vh;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.Resource .calendar .plans .plan .plan-text{
    text-align: initial;
    margin-left: 0.5em;
}

.Resource .unit p{
    padding: 0;
    margin: 0.3vw 0;
}

.Resource .unit .unit-description{
    max-width: 5vw;
}

.Resource .unit .unit-description .unit-name{
    font-size: 0.8vw;
    line-height: 0.9vw;
}

.Resource .unit .unit-menu .unit-book{
    position: absolute;
    top: -0.8vw;
    left: 3.8vw;
    width: 10vw;
    z-index: 10;
    border-radius: 0;
}

.Resource .unit .unit-menu .unit-book .unit-book-image{
    width: 100%;
    border-radius: 0;
    border: none;
}

.Resource .unit .unit-menu .unit-book .unit-book-text{
    position: absolute;
    z-index: 11;
    top: 0.7vw;
    right: 0.7vw;
    font-size: 1.2vw;
    text-shadow: 1px 1px 2px black;
}

.Resource .unit .unit-menu .unit-plan{
    position: absolute;
    top: 2.6vw;
    left: 2.6vw;
    width: 10vw;
    z-index: 10;
}

.Resource .unit .unit-menu .unit-plan .unit-plan-image{
    width: 100%;
    border-radius: 0;
    border: none;
}

.Resource .unit .unit-menu .unit-plan .unit-plan-text{
    position: absolute;
    z-index: 11;
    top: 1.9vw;
    right: 0.7vw;
    font-size: 1.2vw;
    text-shadow: 1px 1px 2px black;
}

.Resource .error{

    font-size: 1vw;
}
