.App .static-map{
    position: absolute;
    left: 5%;

    width: 100%;
    height: 100%;
}
.App .static-map img{
    height: 80%;
    max-width: 95%;
    max-height: 83%;/*95%;*/
    margin: 3%;
}


.maps .maps-container{
    /*box-sizing: border-box;*/
    position: relative;
    top: 2vh;
    margin: auto;
    left: 5%;

    /*float: left;*/
    padding: 1vh;
    width: 40vw;
    height: 5vh;
}

.maps .maps-container .map{
    /*float: left; 
    margin: 0 0.5vh;*/
    margin: auto;
    width: 18vw;

    text-align: left;
    padding: 1vh;
    font-weight: bold;
    margin-bottom: 1vh;
    color: white;
    background: rgba(255, 255, 255, 0.07);
    box-shadow: 0 0 4px 2px rgba(0,0,0,0.1);
    border-radius: 0.7vh;
}

.maps .maps-container .map.active{
    color: black;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
}


.maps .preview{
    box-sizing: border-box;
    float: left;
    padding: 5vh;
    width: 70vw;
    height: 100vh;
}

.maps .preview embed{
    width: 100%;
    height: 100%;
}
