.carousel-container {
    margin-left: 5%;
    width: 95%;
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 78%;
}

.carousel-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-content {
    height: 100%;
    display: flex;
    transition: all 350ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

/*BUttons*/
.left-arrow, .right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    font-size: 5vh;
    /*
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: white;
    border: 1px solid #ddd;
    */
}

.left-arrow {
    left: 0.5vw;
}

.right-arrow {
    right: 0.5vw;
}